<template>
  <div style="max-width:450px;" class="mx-auto my-auto"
    v-if="$vuetify.breakpoint.smAndDown ? isShowing : $router.push({ name: 'Home' })">
    <v-img contain :src="itemImg" :alt="songInfo && songInfo.title ? songInfo.title : 'Graceway Radio'"
      onerror="this.src='https://gracewayradio.com/artwork/customMissing.jpg'">
      <v-icon @click="closeFullPagePlayer()" absolute class="gesture" large dark>mdi-close</v-icon>
      <v-icon class="gesture" dark large right absolute @click="viewSongInfo(songInfo)">mdi-eye</v-icon>
      <radio-quality-fab-button :btnAbsolute="true" btnStyle="top:5px; left:80%" />
    </v-img>
    <div class="album-art" style="position: relative">
      <v-btn fab color="grey lighten-1" large bottom right absolute @click="playPause()">
        <v-icon large v-if="!audioIsPlaying">mdi-play</v-icon>
        <v-icon large v-else>mdi-pause</v-icon>
      </v-btn>
    </div>
    <div class="song-meta white--text">
      <div class="text-h6 marquee-container">
        <div :class="[marqueeTrigger(songInfo, 'title', 18) ? 'marquee' : '']">
          {{ songInfo && songInfo.title ? songInfo.title : "" }}
        </div>
      </div>
      <div class="text-body-2 marquee-container">
        <div :class="[marqueeTrigger(songInfo, 'artist', 36) ? 'marquee' : '']">
          {{ songInfo && songInfo.artist ? songInfo.artist : "" | separate }}
        </div>
      </div>
    </div>
    <history-queue></history-queue>
  </div>
</template>

<script>
import HistoryQueue from "@/components/songInfo/HistoryQueue.vue";
import RadioQualityFabButton from "@/components/stream/RadioQualityFabButton.vue";

export default {
  name: "FullPagePlayer",
  data: () => ({
    isShowing: true,
    historyUpcoming: "upcoming"
  }),
  props: {
    openPlayer: {
      type: Boolean,
      default: false
    }
  },
  metaInfo() {
    return {
      title: "Full Page Player",
      meta: [{ vmid: "robots", name: "robots", content: "index,follow" }]
    };
  },
  components: { HistoryQueue, RadioQualityFabButton },
  computed: {
    audioIsPlaying() {
      return this.$store.state?.audio?.audioIsPlaying;
    },
    loadingSongInfo() {
      return this.$store.state?.loadingSongInfo;
    },
    songInfo() {
      return this.$store.state?.songInfo;
    },
    itemImg() {
      return this.$store.getters.itemImg(this.songInfo);
    }
  },
  methods: {
    closeFullPagePlayer() {
      this.$router.go(-1);
    },
    playPause() {
      this.$store.dispatch("audio/audioPlayToggle");
    },
    viewSongInfo(songObj) {
      this.$store.dispatch("viewSongInfo", songObj);
    },
    marqueeTrigger(el, att, val) {
      return !!(el && el[att] && el[att].length > val);
    }
  }
};
</script>

<style>
.gesture {
  border-radius: 50%;
  background-color: black;
  opacity: 0.4;
  margin: 0.5em 0.5em;
  padding: 0.2em;
}

.song-meta {
  padding-top: 5px;
  padding-left: 5px;
  height: 65px;
  background-color: dodgerblue;
}

.album-art {
  max-height: 300px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-130%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-130%);
  }
}

.marquee-container {
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  animation: marquee 8s linear infinite;
  -webkit-animation: marquee 8s linear infinite;
}

.marquee:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
</style>
